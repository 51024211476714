import getTimestamp from '~/utils/getTimestamp';

export default function throttleRAF(
  callback = (rafId: number): void => {},
  FPS: number = 60,
): number {
  let timeStamp = getTimestamp();

  const rafHandler = (): void => {
    if ((getTimestamp() - timeStamp) < (1000 / FPS)) {
      return requestAnimationFrame(rafHandler);
    }

    timeStamp = getTimestamp();
    callback(requestAnimationFrame(rafHandler));
  };

  return requestAnimationFrame(rafHandler);
}
